import { Component, OnInit } from '@angular/core';
import { AuthService } from '@comsig/core';

import { PermissionsService } from '../../shared/permissions';
import { BrandingService, LogoImage } from '../../shared/branding';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit {
  public showSubnav = false;
  public subnavItems = [];
  public showNav = false;
  public logoImage: LogoImage;

  constructor(
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private brandingService: BrandingService
  ) {}

  ngOnInit() {
    this.logoImage = this.brandingService.logoImage;
    this.showNav = this.permissionsService.hasPermission('viewCategoryTracker');
  }

  openSubnav(menuItems) {
    this.showSubnav = true;
    this.subnavItems = menuItems;
  }

  closeSubnav() {
    this.showSubnav = false;
    this.subnavItems = [];
  }

  get title(): string {
    return this.brandingService.title;
  }
}
