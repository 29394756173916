import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateTime } from 'luxon';
import * as Highcharts from 'highcharts';

import { NumeralService } from '../../../shared/numeral';
import { DateRange } from '../../../shared/common-api';
import { Filters, FiltersService } from '../../../shared/filters';
import {
  HomeService,
  Metric,
  PerformanceApiParams,
  PerformanceTrendline,
  PerformanceTrendlineDate,
} from '../../';

@Component({
    selector: 'app-home-performance-trendline',
    templateUrl: './home-performance-trendline.component.html',
    styleUrls: ['./home-performance-trendline.component.scss'],
    standalone: false
})
export class HomePerformanceTrendlineComponent implements OnChanges {
  Highcharts = Highcharts;

  @Input() filters: Filters;
  @Input() metric: Metric;
  @Input() amountKey: string;
  @Input() dateRange: DateRange;
  @Input() hasCustomerCategoryData: boolean;
  @Input() isNonCustomerTotalPopulation: boolean;

  public tabs = ['By Population Segment', 'By Population Segment Value'];

  public performanceTrendline: PerformanceTrendline;
  public chartInstance: any;
  public chartOptions: any;
  public isLoading = true;
  public selectedIndex = 0;

  private currentQuery: Subscription;

  constructor(
    private numeralService: NumeralService,
    private filtersService: FiltersService,
    private homeService: HomeService
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['filters'] || simpleChanges['dateRange'] || simpleChanges['amountKey']) {
      this.retrieveData();
      return;
    }

    this.buildChart();
  }

  buildChart(): void {
    const isRate = this.isDeltas;
    const numeralService = this.numeralService;
    const metric = this.metric;
    const trendlineDates: PerformanceTrendlineDate[] = this.validTrendlineDates;

    this.chartOptions = {
      chart: {
        animation: false,
        backgroundColor: 'transparent',
        zoomType: 'xy',
        panning: true,
        panKey: 'shift',
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        labels: {
          format: `${!isRate && this.metric.isCurrency ? numeralService.currencySymbol : ''}{text}${
            this.isDeltas ? '%' : ''
          }`,
        },
        title: {
          text: this.metric.name,
        },
      },
      xAxis: {
        title: {
          text: 'To zoom into chart, drag a rectangle around the relevant section',
        },
        categories: trendlineDates.map((dateResults) =>
          DateTime.fromISO(dateResults.date).toFormat('M/yyyy')
        ),
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginTop: 10,
        title: {
          style: { fontWeight: 'normal' },
          text: 'Click to toggle on/off:',
        },
      },
      tooltip: {
        formatter: function () {
          const amount = numeralService.formatMetric(this.y, {
            isRate,
            isCurrency: !isRate && metric.isCurrency,
          });

          return `${this.x}<br>${this.series.name}: <b>${amount}</b>`;
        },
      },
      plotOptions: {
        series: {
          lineWidth: 3,
          animation: false,
          marker: {
            enabled: false,
            symbol: 'square',
          },
        },
      },
      series: [],
    };

    this.addChartSeries(trendlineDates);
  }

  addChartSeries(trendlineDates: PerformanceTrendlineDate[]): void {
    const colors = ['#49a7db', '#f5ba47', '#90ba39', '#4a4380', '#24CBE5', '#64E572', '#FFF263'];
    const seriesList = [];

    const amountOf = (dateResults, segmentId, valueKey) => {
      let amount = dateResults.results?.[segmentId]?.values[this.metric.id]?.[valueKey] ?? null;

      if (this.isDeltas && amount !== null) {
        amount *= 100;
      }

      return amount;
    };

    const chartLines = this.performanceTrendline.segments.map((segment) => ({
      ...segment,
      customers: trendlineDates.map((dateResults) =>
        amountOf(dateResults, segment.id, 'customers')
      ),
      nonCustomers: trendlineDates.map((dateResults) =>
        amountOf(dateResults, segment.id, 'nonCustomers')
      ),
    }));

    if (this.isEntirePopulation) {
      chartLines.forEach((chartLine) => {
        const prefix =
          chartLine.id === 'PARTNER'
            ? this.filters.partner.name
            : this.filters.partner.category.name;
        const dashStyle = chartLine.id === 'PARTNER' ? undefined : 'ShortDot';

        if (chartLine.id === 'PARTNER' || this.hasCustomerCategoryData) {
          seriesList.push({
            name: `${prefix}<br>Avios Customer`,
            data: chartLine.customers,
            color: '#2fa2d7',
            isCustomer: true,
            dashStyle,
          });
        }

        seriesList.push({
          name: `${prefix}<br>Non Avios Customer`,
          data: chartLine.nonCustomers,
          color: this.isNonCustomerTotalPopulation ? 'rgba(250, 175, 46, 0.5)' : '#faaf2d',
          isCustomer: false,
          dashStyle,
        });
      });
    } else {
      chartLines.forEach((chartLine, index) => {
        seriesList.push({
          name: `Avios Customer ${chartLine.name}`,
          data: chartLine.customers,
          color: colors[index],
          isCustomer: true,
        });

        if (!this.isNonCustomerTotalPopulation) {
          seriesList.push({
            name: `Non Avios Customer ${chartLine.name}`,
            data: chartLine.nonCustomers,
            color: colors[index],
            dashStyle: 'ShortDot',
            isCustomer: false,
          });
        }

        if (this.isNonCustomerTotalPopulation && index === chartLines.length - 1) {
          seriesList.push({
            name: 'Non Avios Customer',
            data: chartLine.nonCustomers,
            color: 'rgba(250, 175, 46, 0.5)',
            dashStyle: 'ShortDot',
            isCustomer: false,
          });
        }
      });
    }

    if (this.chartInstance) {
      this.removeAllSeries();
      seriesList.forEach((series) => this.chartInstance.addSeries(series));
    } else {
      this.chartOptions.series = seriesList;
    }
  }

  retrieveData(): void {
    this.isLoading = true;

    if (this.currentQuery !== undefined) {
      this.currentQuery.unsubscribe();
    }

    const params: PerformanceApiParams = {
      ...this.filtersService.paramsFrom(this.filters),
      startOn: this.dateRange.startOn.toISODate(),
      endOn: this.dateRange.endOn.toISODate(),
      isEntirePopulation: this.isEntirePopulation,
      isDeltas: this.isDeltas,
    };

    this.currentQuery = this.homeService
      .performanceTrendline(params)
      .pipe(first())
      .subscribe((performanceTrendline: PerformanceTrendline) => {
        this.performanceTrendline = performanceTrendline;

        this.isLoading = false;
        this.buildChart();
      });
  }

  get validTrendlineDates(): PerformanceTrendlineDate[] {
    const removeEmptyDateValues = (performanceTrendlineDate: PerformanceTrendlineDate) =>
      performanceTrendlineDate.results[this.performanceTrendline.segments[0].id][this.amountKey] !==
      null;

    return this.performanceTrendline.dates.filter(removeEmptyDateValues);
  }

  get isEntirePopulation(): boolean {
    return this.selectedIndex === 0;
  }

  get isDeltas(): boolean {
    return this.amountKey === 'deltas';
  }

  onChartCreation(chart: any): void {
    this.chartInstance = chart;
  }

  removeAllSeries(): void {
    for (let i = this.chartInstance.series.length - 1; i >= 0; i--) {
      this.chartInstance.series[i].remove();
    }
  }

  tabChanged(index: number): void {
    this.selectedIndex = index;

    this.retrieveData();
  }
}
