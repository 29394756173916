import { Component, OnInit } from '@angular/core';
import { debounceTime, first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Filters, FiltersService } from './';
import {
  CommonApiService,
  Partner,
  PopulationSegmentOption,
  PopulationSegmentOptionValue,
} from '../common-api';
import { customerTypeOptions } from './filters.items';

@UntilDestroy()
@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: false
})
export class FiltersComponent implements OnInit {
  public filters: Filters;

  public partners: Partner[] = [];
  public populationSegmentOptions: PopulationSegmentOption[] = [];
  public populationSegmentValueOptions: PopulationSegmentOptionValue[] = [];
  public customerTypeOptions = customerTypeOptions;

  private debouncer = new Subject();

  constructor(private filtersService: FiltersService, private commonApiService: CommonApiService) {}

  ngOnInit(): void {
    this.filters = {
      partner: null,
      customerTypeId: this.customerTypeOptions[0].value,
      populationSegment: null,
      populationSegmentValues: [],
    };

    this.retrievePartners();
    this.retrievePopulationSegmentOptions();

    this.debouncer
      .pipe(untilDestroyed(this), debounceTime(750))
      .subscribe(() => this.onFilterChange());
  }

  onPopulationSegmentChange(): void {
    this.buildPopulationSegmentValueOptions();
    this.onFilterChange();
  }

  buildPopulationSegmentValueOptions(): void {
    this.populationSegmentValueOptions = this.filters.populationSegment.valueOptions;
    this.filters.populationSegmentValues = [
      ...this.filters.populationSegment.valueOptions.map((valueOption) => valueOption.id),
    ];
  }

  onDebouncedFilterChange(): void {
    this.debouncer.next(null);
  }

  onFilterChange(): void {
    if (!this.partners.length || !this.populationSegmentOptions.length) {
      return;
    }

    this.filtersService.update({
      ...this.filters,
    });
  }

  retrievePartners(): void {
    this.commonApiService
      .partners()
      .pipe(first())
      .subscribe((partners: Partner[]) => {
        this.partners = partners;
        this.filters.partner = this.partners[0];

        this.onFilterChange();
      });
  }

  retrievePopulationSegmentOptions(): void {
    this.commonApiService
      .populationSegmentOptions()
      .pipe(first())
      .subscribe((populationSegmentOptions: PopulationSegmentOption[]) => {
        this.populationSegmentOptions = populationSegmentOptions;
        this.filters.populationSegment = this.populationSegmentOptions[0];
        this.buildPopulationSegmentValueOptions();

        this.onFilterChange();
      });
  }
}
