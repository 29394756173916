import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BrandingService } from '../../shared/branding';
import { first } from 'rxjs/operators';
import { CommonApiService, Partner } from '../../shared/common-api';

@UntilDestroy()
@Component({
    selector: 'app-help-index',
    templateUrl: './help-index.component.html',
    styleUrls: ['./help-index.component.scss'],
    standalone: false
})
export class HelpIndexComponent implements OnInit, AfterViewChecked {
  private fragment: string;

  public partners: Partner[] = [];

  constructor(
    private route: ActivatedRoute,
    private brandingService: BrandingService,
    private commonApiService: CommonApiService
  ) {}

  ngOnInit() {
    this.route.fragment.pipe(untilDestroyed(this)).subscribe((fragment: string) => {
      this.fragment = fragment;
    });

    this.retrievePartners();
  }

  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        document.querySelector('#' + this.fragment).scrollIntoView();
      }
    } catch (e) {}
  }

  scrollTo(id: string): void {
    const elmnt = document.getElementById(id);
    elmnt.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  get productTitle(): string {
    return this.brandingService.title;
  }

  get supportEmail(): string {
    return this.brandingService.supportEmail;
  }

  retrievePartners(): void {
    this.commonApiService
      .partners()
      .pipe(first())
      .subscribe((partners: Partner[]) => {
        this.partners = partners;
      });
  }
}
