import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-content-placeholder-section',
    templateUrl: './content-placeholder-section.component.html',
    styleUrls: ['./content-placeholder-section.component.scss'],
    standalone: false
})
export class ContentPlaceholderSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
