import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService, AppSettingsService } from '@comsig/core';

import { SaasProvidersService } from './shared/saas-providers';
import { RoutingStateService } from './shared/routing-state';
import { PermissionsService } from './shared/permissions';
import { BrandingService } from './shared/branding';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  public userLoaded = false;

  constructor(
    private router: Router,
    private routingStateService: RoutingStateService,
    private titleService: Title,
    private appSettingsService: AppSettingsService,
    private saasProvidersService: SaasProvidersService,
    private permissionsService: PermissionsService,
    private authService: AuthService,
    private brandingService: BrandingService
  ) {
    this.routingStateService.loadRouting();
  }

  ngOnInit() {
    this.listenOnRouteChanges();

    this.authService.onAuthUserSet = () => {
      this.saasProvidersService.init(this.appSettingsService.getSettings());
      this.permissionsService.setupPermissions();

      this.userLoaded = true;

      this.titleService.setTitle(this.brandingService.title);
    };
  }

  listenOnRouteChanges() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((route) => {
      if (route instanceof RoutesRecognized) {
        if (route.url === '/login') {
          this.userLoaded = false;
        }
      }
    });
  }
}
