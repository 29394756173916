import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-card-tab-nav',
    templateUrl: './card-tab-nav.component.html',
    styleUrls: ['./card-tab-nav.component.scss'],
    standalone: false
})
export class CardTabNavComponent implements OnInit {
  @Input() tabs: string[];
  @Input() removePadding = false;

  @Output() selectedIndexChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  tabChanged(index: number): void {
    this.selectedIndexChange.emit(index);
  }
}
