import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import * as Highcharts from 'highcharts';

import { NumeralService } from '../../../shared/numeral';
import { Filters, FiltersService } from '../../../shared/filters';
import {
  CustomerPopulationCounts,
  HomeService,
  KpisApiParams,
  PerformanceApiParams,
  Timeframe,
} from '../../';
import { customerTypeOptions } from '../../../shared/filters/filters.items';

@Component({
    selector: 'app-home-kpis-customers-chart',
    templateUrl: './home-kpis-customers-chart.component.html',
    styleUrls: ['./home-kpis-customers-chart.component.scss'],
    standalone: false
})
export class HomeKpisCustomersChartComponent implements OnChanges {
  Highcharts = Highcharts;

  @Input() filters: Filters;
  @Input() timeframe: Timeframe;
  @Input() isNonCustomerTotalPopulation: boolean;

  public customerPopulationOptions = customerTypeOptions;
  public chartInstance: any;
  public chartOptions: any;
  public activeCustomers: CustomerPopulationCounts;
  public isLoading = true;

  private currentQuery: Subscription;

  constructor(
    private numeralService: NumeralService,
    private filtersService: FiltersService,
    private homeService: HomeService
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['filters'] || simpleChanges['timeframe']) {
      this.retrieveData();
      return;
    }

    this.buildChart();
  }

  buildChart(): void {
    const numeralService = this.numeralService;
    const isNonCustomerTotalPopulation = this.isNonCustomerTotalPopulation;

    const buildSeriesFor = (segment) => {
      return this.customerPopulationOptions.map(
        (customerPopulationOption) => this.activeCustomers[customerPopulationOption.value][segment]
      );
    };

    this.chartOptions = {
      chart: {
        type: 'column',
        animation: false,
        backgroundColor: 'transparent',
        height: '280px',
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.customerPopulationOptions.map((customerPopulationOption) =>
          customerPopulationOption.name.replace('Partner', this.filters.partner.name)
        ),
      },
      yAxis: {
        title: {
          text: 'Number of Customers',
        },
        plotLines: [
          {
            value: 0,
            color: '#939393',
            width: 2,
            zIndex: 2,
          },
        ],
      },
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        symbolRadius: 0,
        itemMarginTop: 10,
        title: {
          style: { fontWeight: 'normal' },
          text: 'Click to toggle on/off:',
        },
      },
      tooltip: {
        formatter: function () {
          const amount = numeralService.formatMetric(this.y);
          const tip =
            isNonCustomerTotalPopulation && this.series.userOptions.id === 'nonCustomers'
              ? '<br><br>* Non Avios customers value reflects total Non Avios Population'
              : '';

          return `${this.series.name}: <b>${amount}</b>${tip}`;
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          animation: false,
        },
        column: {
          minPointLength: 2,
        },
      },
      series: [
        {
          id: 'customers',
          name: 'Avios Customers',
          data: buildSeriesFor('customers'),
          color: '#2fa2d7',
        },
        {
          id: 'nonCustomers',
          name: 'Non Avios Customers',
          data: buildSeriesFor('nonCustomers'),
          color: '#faaf2d',
        },
      ],
    };

    this.highlightActiveColumn();
  }

  highlightActiveColumn(): void {
    if (!this.chartInstance) {
      return;
    }

    const activeIndex = this.customerPopulationOptions
      .map((customerPopulationOption) => customerPopulationOption.value)
      .indexOf(this.filters.customerTypeId);

    const updateColor = (column, color) =>
      column.update({
        color,
      });

    this.chartInstance.series[0].data.forEach((column, index) =>
      updateColor(column, index === activeIndex ? '#2fa2d7' : 'rgba(47, 162, 215, 0.5)')
    );

    this.chartInstance.series[1].data.forEach((column, index) =>
      updateColor(
        column,
        index === activeIndex && !this.isNonCustomerTotalPopulation
          ? '#faaf2d'
          : 'rgba(250, 175, 46, 0.5)'
      )
    );
  }

  onChartCreation(chart: any): void {
    this.chartInstance = chart;

    this.highlightActiveColumn();
  }

  retrieveData(): void {
    this.isLoading = true;

    if (this.currentQuery !== undefined) {
      this.currentQuery.unsubscribe();
    }

    const params: KpisApiParams = {
      ...this.filtersService.paramsFrom(this.filters, false),
      timeframeId: this.timeframe.id,
    };

    this.currentQuery = this.homeService
      .activeCustomers(params)
      .pipe(first())
      .subscribe((activeCustomers: CustomerPopulationCounts) => {
        this.activeCustomers = activeCustomers;

        this.isLoading = false;
        this.buildChart();
      });
  }
}
