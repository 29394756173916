import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

import { DateTime } from 'luxon';

@Component({
    selector: 'app-datepicker-monthly',
    templateUrl: './datepicker-monthly.component.html',
    styleUrls: ['./datepicker-monthly.component.scss'],
    standalone: false
})
export class DatepickerMonthlyComponent implements OnInit {
  @Input() title: string = '';
  @Input() initialDate: Date;
  @Input() isEndOfMonth = false;

  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Output() dateSelectionChange: EventEmitter<Date> = new EventEmitter();

  public dateFormControl = new UntypedFormControl();

  constructor() {}

  ngOnInit(): void {
    this.dateFormControl.setValue(this.initialDate);
  }

  monthSelected(selectedDate: Date, datepicker: MatDatepicker<any>): void {
    if (this.isEndOfMonth) {
      selectedDate = DateTime.fromJSDate(selectedDate)
        .endOf('month')
        .toJSDate();
    }

    this.dateFormControl.setValue(selectedDate);
    this.dateSelectionChange.emit(selectedDate);

    datepicker.close();
  }

  get monthYearLabel(): string {
    return this.dateFormControl.value
      ? DateTime.fromJSDate(this.dateFormControl.value).toFormat('LLL yyyy')
      : '';
  }
}
