import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileSaverService } from 'ngx-filesaver';

import { Filters, FiltersService } from '../../shared/filters';
import { Kpis, HomeService, Metric, Timeframe, KpisApiParams } from '../';
import { metrics } from '../home.items';

@UntilDestroy()
@Component({
    selector: 'app-home-kpis',
    templateUrl: './home-kpis.component.html',
    styleUrls: ['./home-kpis.component.scss'],
    standalone: false
})
export class HomeKpisComponent implements OnInit {
  public filters: Filters;
  public metrics: Metric[] = metrics;
  public kpis: Kpis;
  public timeframes: Timeframe[];
  public timeframe: Timeframe;
  public isLoading = true;

  private currentQuery: Subscription;

  constructor(
    private fileSaverService: FileSaverService,
    private filtersService: FiltersService,
    private homeService: HomeService
  ) {}

  ngOnInit(): void {
    this.retrieveTimeframes();

    this.filtersService.filters$
      .pipe(untilDestroyed(this))
      .subscribe((filters) => this.onSharedFilterChange(filters));
  }

  retrieveTimeframes(): void {
    this.isLoading = true;

    if (this.currentQuery !== undefined) {
      this.currentQuery.unsubscribe();
    }

    this.currentQuery = this.homeService
      .kpiTimeframes()
      .pipe(first())
      .subscribe((timeframes: Timeframe[]) => {
        this.timeframes = timeframes;
        this.timeframe = timeframes[0];

        if (this.isReadyToRetrieveKpis) {
          this.retrieveKpis();
        }
      });
  }

  get isReadyToRetrieveKpis(): boolean {
    return this.filters !== undefined && this.timeframe !== undefined;
  }

  retrieveKpis(): void {
    this.isLoading = true;

    if (this.currentQuery !== undefined) {
      this.currentQuery.unsubscribe();
    }

    const params: KpisApiParams = {
      ...this.filtersService.paramsFrom(this.filters),
      timeframeId: this.timeframe.id,
    };

    this.currentQuery = this.homeService
      .kpis(params)
      .pipe(first())
      .subscribe((kpis: Kpis) => {
        this.kpis = kpis;
        this.kpis.sales.index = this.kpis.sales.nonCustomers
          ? this.kpis.sales.customers / this.kpis.sales.nonCustomers
          : null;
        this.kpis.transactions.index = this.kpis.transactions.nonCustomers
          ? this.kpis.transactions.customers / this.kpis.transactions.nonCustomers
          : null;
        this.kpis.avgBasketSize.index = this.kpis.avgBasketSize.nonCustomers
          ? this.kpis.avgBasketSize.customers / this.kpis.avgBasketSize.nonCustomers
          : null;

        this.isLoading = false;
      });
  }

  get isNonCustomerTotalPopulation(): boolean {
    return this.filtersService.isNonCustomerTotalPopulation(this.filters);
  }

  onSharedFilterChange(filters: Filters): void {
    if (filters === null) {
      return;
    }

    this.filters = filters;

    if (this.isReadyToRetrieveKpis) {
      this.retrieveKpis();
    }
  }

  download(): void {
    const params: KpisApiParams = {
      ...this.filtersService.paramsFrom(this.filters),
      timeframeId: this.timeframe.id,
    };

    const filename = this.filtersService.exportFilename(
      `insights_export_kpis_${this.timeframe.displayStartOn}-${this.timeframe.displayEndOn}`,
      params
    );

    this.homeService
      .exportKpis(params)
      .subscribe((response) => this.fileSaverService.save(response.body, `${filename}.xlsx`));
  }
}
