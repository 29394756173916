import { Component, Input, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

import { PopulationSegmentOption } from '../../../shared/common-api';
import { NumeralService } from '../../../shared/numeral';
import { Metric, PopulationSegmentPerformance } from '../../home.model';

@Component({
    selector: 'app-home-performance-merchant-category-chart',
    templateUrl: './home-performance-merchant-category-chart.component.html',
    styleUrls: ['./home-performance-merchant-category-chart.component.scss'],
    standalone: false
})
export class HomePerformanceMerchantCategoryChartComponent implements OnChanges {
  Highcharts = Highcharts;

  @Input() chartTitle: string;
  @Input() metric: Metric;
  @Input() populationSegment: PopulationSegmentOption;
  @Input() populationSegmentPerformance: PopulationSegmentPerformance[];
  @Input() amountKey;
  @Input() isNonCustomerTotalPopulation: boolean;
  @Input() hasCustomerData = true;

  public chartInstance: any;
  public chartOptions: any;

  constructor(private numeralService: NumeralService) {}

  ngOnChanges(): void {
    this.buildChart();

    if (this.chartInstance) {
      this.chartInstance.reflow();
    }
  }

  buildChart(): void {
    const isRate = this.isRate;
    const numeralService = this.numeralService;
    const metric = this.metric;
    const isNonCustomerTotalPopulation = this.isNonCustomerTotalPopulation;

    this.chartOptions = {
      chart: {
        type: 'column',
        animation: false,
        backgroundColor: 'transparent',
        marginBottom: 110,
      },
      title: {
        text: null,
      },
      subtitle: {
        text: this.hasCustomerData
          ? null
          : 'Avios Customers not available for selected Customer Population',
        floating: true,
        style: { color: '#000000' },
        y: 368,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: this.populationSegmentPerformance.map(
          (populationSegmentPerformance) => populationSegmentPerformance.name
        ),
        title: {
          text: `${this.populationSegment.name} Buckets`,
        },
      },
      yAxis: {
        labels: {
          format: `${!isRate && this.metric.isCurrency ? numeralService.currencySymbol : ''}{text}${
            this.isRate ? '%' : ''
          }`,
        },
        title: {
          text: this.metric.name,
        },
        plotLines: [
          {
            value: 0,
            color: '#939393',
            width: 3,
            zIndex: 2,
          },
        ],
      },
      legend: {
        enabled: this.hasCustomerData,
        symbolRadius: 0,
        align: 'left',
        x: 65,
        title: {
          style: { fontWeight: 'normal' },
          text: 'Click to toggle on/off:',
        },
      },
      tooltip: {
        formatter: function () {
          const amount = numeralService.formatMetric(this.y, {
            isRate,
            isCurrency: !isRate && metric.isCurrency,
          });
          const tip =
            isNonCustomerTotalPopulation && this.series.userOptions.id === 'nonCustomers'
              ? '<br><br>* Non Avios customers value reflects total Non Avios Population'
              : '';

          return `${this.series.name}: <b>${amount}</b>${tip}`;
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          animation: false,
        },
        column: {
          minPointLength: 1,
        },
      },
      series: [
        {
          id: 'customers',
          name: 'Avios Customers',
          data: this.populationSegmentPerformance.map((populationSegmentPerformance) =>
            this.amountKey === 'deltas'
              ? populationSegmentPerformance[this.amountKey][this.metric.id].customers * 100
              : populationSegmentPerformance[this.amountKey][this.metric.id].customers
          ),
          color: '#2fa2d7',
        },
        {
          id: 'nonCustomers',
          name: 'Non Avios Customers',
          data: this.populationSegmentPerformance.map((populationSegmentPerformance) =>
            this.amountKey === 'deltas'
              ? populationSegmentPerformance[this.amountKey][this.metric.id].nonCustomers * 100
              : populationSegmentPerformance[this.amountKey][this.metric.id].nonCustomers
          ),
          color: this.isNonCustomerTotalPopulation ? 'rgba(250, 175, 46, 0.5)' : '#faaf2d',
        },
      ],
    };
  }

  get isRate(): boolean {
    return this.amountKey === 'deltas';
  }

  onChartCreation(chart: any): void {
    this.chartInstance = chart;
  }
}
