import {
  Component,
  EventEmitter,
  AfterViewInit,
  Input,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    standalone: false
})
export class SearchBoxComponent implements AfterViewInit {
  @Input() public defaultValue = '';
  @Input() public defaultDebounce = 150;
  @Output() public searching = new EventEmitter<string>();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(this.defaultDebounce),
        distinctUntilChanged(),
        tap((event: any) => {
          this.searching.emit(event.target.value);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
