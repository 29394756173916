import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'metric',
    standalone: false
})
export class MetricPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number, type: string = null): string {
    if (value === null) {
      return '--';
    }

    if (!isFinite(value)) {
      return 'New';
    }

    let decimals = 0;
    let prefix = '';
    let units = '';
    let suffix = '';

    switch (type) {
      case 'rate':
        value *= 100;
        suffix = '%';
        decimals = 1;
        break;

      case 'currency':
        prefix = '$';
        decimals = 2;
        break;
    }

    if (value > 1000000) {
      value /= 1000000;
      units = 'M';
      decimals = 2;
    } else if (value > 1000) {
      value /= 1000;
      units = 'K';
      decimals = 2;
    }

    return `${prefix}${this.decimalPipe.transform(
      value,
      `1.${decimals}-${decimals}`
    )}${units}${suffix}`;
  }
}
